import { Link } from 'gatsby';
import { css, styled } from 'styled-components';

import Car from '../../static/svgIcons/Car.svg';
import Clock from '../../static/svgIcons/Clock.svg';
import CoinPile from '../../static/svgIcons/CoinPile.svg';
import Court from '../../static/svgIcons/Court.svg';
import Key from '../../static/svgIcons/Key.svg';
import Phone from '../../static/svgIcons/Phone.svg';
import PolicyExpertBronze from '../../static/svgIcons/PolicyExpertBronze.svg';
import PolicyExpertGold from '../../static/svgIcons/PolicyExpertGold.svg';
import PolicyExpertSilver from '../../static/svgIcons/PolicyExpertSilver.svg';
import Scale from '../../static/svgIcons/Scale.svg';
import Tick from '../../static/svgIcons/Tick.svg';
import TimerSpeed from '../../static/svgIcons/TimerSpeedPink.svg';
import Triangle from '../../static/svgIcons/Triangle.svg';

export const fontFamilyFallback =
  '"Helvetica Neue", Helvetica, Arial, sans-serif';

export const fontFamilyCamptonSemiBold = `campton-semi-bold, ${fontFamilyFallback}`;
export const fontFamilyCamptonRegular = `campton-regular, ${fontFamilyFallback}`;
export const fontFamilyCamptonBook = `campton-book, ${fontFamilyFallback}`;
export const fontFamilyCamptonLight = `campton-light, ${fontFamilyFallback}`;
export const fontFamilyCamptonMedium = `campton-medium, ${fontFamilyFallback}`;

export const SemiBoldSpan = styled.span<{ $isPurple?: boolean }>`
  font-family: ${fontFamilyCamptonSemiBold};
  color: ${({ $isPurple }) => ($isPurple ? colors.purple7 : colors.black)};
`;
export const colors = {
  white: '#ffffff',
  lightBlack: '#161616',
  black: '#000000',
  blackAlpha15: '#00000026',
  blackAlpha20: '#00000033',
  blackAlpha25: '#00000040',
  blackAlpha50: '#00000080',

  gray1: '#fcfbfd',
  gray2: '#f9f9f9',
  gray3: '#e9efef',
  gray4: '#e2e2e2',
  gray5: '#cccccc',
  gray6: '#dddddd',
  gray7: '#bdbdbd',
  gray8: '#808080',
  gray9: '#5e5e5e',
  gray10: '#333333',
  gray11: '#bdbdbd4d',
  gray12: '#bdbdbd60',
  gray13: '#00000066',
  gray14: '#36363699',
  gray15: '#36363640',
  gray16: '#767676',
  gray17: '#D1D1D1',
  gray18: '#A1A1A1',

  boxShadowGray: '#b2b2b2',
  gray10Translucent: '#3333331F',

  charcoal: '#363636',

  pink1: '#f26aa8',
  pink2: '#ff00a0',
  pink3: '#f04e98',
  pink6: '#ec1f7d',
  pink10: '#df1370',
  pink11: '#FFD9F1',

  purple1: '#f2f0f5',
  purple2: '#b089df',
  purple3: '#efe7f8',
  purple4: '#a79cbd',
  purpleAlpha5: '#f8f3fd',
  purple6: '#967dc2',
  purple7: '#6214be',
  purple7Alpha5: '#6214be0d',
  purple7Alpha10: '#6214be1a',
  purple7Alpha20: '#6214be33',
  purple7Alpha30: '#6214be4D',
  purple8: '#6d4da5',
  purple9: '#5a3d83',
  purple10: '#563d82',
  purple11: '#e0d0f2',
  purple12: '#7937C8',
  purple13: '#D2BDEC',
  purple14: '#F0E8F9',
  purple15: '#E8DCF5',

  opaquePurple: '#d6cfe0FF',
  transparentPurple: '#d6cfe000',

  lilac: '#dfc8f9',
  lightLilac: '#f6f1fc',

  tan1: '#E7DCC5',

  green1: '#9cf9d7',
  green2: '#9ab23b',
  orange: '#cd7438',

  red1: '#ffe7ea',
  red5: '#f56565',
  red10: '#ff475d',

  backDrop: 'rgba(0, 0, 0, 0.25)',
  opaqueGray: 'rgba(255, 255, 255, 0.25)',
  opaqueGray2: 'rgba(86, 61, 130, 0.02)',

  yellow: '#fdb414',

  blue: '#0935d4',
};

export const ProductPageHeaderBannerTitle = styled.h1`
  font-family: ${fontFamilyCamptonMedium};
  font-size: 40px;
  margin: 40px 0 15px;

  @media (min-width: 768px) {
    font-size: 40px;
    margin: 0 0 25px;
  }

  @media (min-width: 1024px) {
    font-size: 65px;
    margin: 0 0 30px;
  }
`;

export const ProductPageHeaderBannerText = styled.p`
  font-family: ${fontFamilyCamptonBook};
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: 390px) {
    font-size: 20px;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    margin-bottom: 50px;
    text-align: left;
  }

  @media (min-width: 1024px) {
    font-size: 28px;
    margin-bottom: 60px;
  }
`;

export const HeroBannerImageContainer = styled.div<{
  $isPet?: boolean;
}>`
  height: 300px;
  width: 248px;

  @media (min-width: 390px) {
    height: 320px;
    width: ${({ $isPet }) => ($isPet ? '270px' : '266px')};
  }

  @media (min-width: 768px) {
    height: 380px;
    width: 321px;
  }

  @media (min-width: 1024px) {
    height: 450px;
    width: 380px;
  }
`;

export const PrimaryButton = styled.a`
  background-color: ${colors.pink2};
  display: flex;
  font-weight: bold;
  padding: 0 32px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  text-align: center;
  color: ${colors.white};
  border-width: 2px;
  border-color: ${colors.pink2};

  &:disabled {
    opacity: 0.5;
    background-color: ${colors.pink2};
    color: ${colors.white};
  }

  &:hover {
    background-color: ${colors.white};
    color: ${colors.pink2};
    border-color: ${colors.pink2};
  }
`;

export const SecondaryButton = styled.button`
  border-color: ${colors.pink2};
  white-space: nowrap;
  vertical-align: middle;
  display: flex;
  font-family: ${fontFamilyCamptonSemiBold};
  background-color: ${colors.white};
  border-width: 2px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  text-align: center;
  color: ${colors.pink2};
  justify-content: center;
  height: 50px;
  padding: 17px;
  width: 131px;
  font-size: 16px;
  border-radius: 15px;
  align-items: center;

  &:hover {
    background-color: ${colors.pink2};
    color: ${colors.white};
  }

  &:disabled {
    opacity: 0.5;
    background-color: ${colors.white};
    color: ${colors.pink2};
  }
`;

export const ImpactSecondaryButton = styled.a`
  border-color: ${colors.pink2};
  display: flex;
  background-color: ${colors.white};
  vertical-align: middle;
  border-width: 2px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  text-align: center;
  color: ${colors.pink2};

  &:hover {
    background-color: ${colors.pink2};
    color: ${colors.white};
  }
`;

export const ProductSvgIcon = css`
  width: 76px;
  height: 60px;
`;

export const PolicyExpertBronzeIcon = styled(PolicyExpertBronze)`
  ${ProductSvgIcon};
`;

export const PolicyExpertSilverIcon = styled(PolicyExpertSilver)`
  ${ProductSvgIcon};
`;

export const PolicyExpertGoldIcon = styled(PolicyExpertGold)`
  ${ProductSvgIcon};
`;

export const SvgIcon = css`
  width: 42px;
  height: 42px;
`;

export const SvgColors = css`
  ${SvgIcon};
  stroke: ${colors.purple7};
  fill: ${colors.purple7};
`;

export const ClockSvg = styled(Clock)`
  ${SvgColors};
`;

export const CarSvg = styled(Car)`
  fill: ${colors.purple7};
  margin-top: 12px;
`;

export const CoinPileSvg = styled(CoinPile)`
  ${SvgIcon};
`;

export const CourtSvg = styled(Court)`
  fill: ${colors.purple7};
  ${SvgIcon};
`;

export const KeySvg = styled(Key)`
  ${SvgColors};
`;

export const PhoneSvg = styled(Phone)`
  ${SvgColors};
`;

export const ScaleSvg = styled(Scale)`
  fill: ${colors.purple7};
  ${SvgIcon};
`;

export const TriangleSvg = styled(Triangle)`
  fill: ${colors.purple7};
  ${SvgIcon};
`;

export const OverflowContainer = styled.div`
  overflow: hidden;
`;

export const TimerSpeedIcon = styled(TimerSpeed)`
  ${SvgColors}
`;

export const TickSvg = styled(Tick)`
  ${SvgColors}
`;

export const MotorDocsContainer = styled.div`
  position: relative;
  background-color: ${colors.gray3};
  padding: 40px 24px;

  @media (min-width: 768px) {
    padding: 50px 40px;
  }

  @media (min-width: 1024px) {
    padding: 120px 0;
  }
`;

export const ProductBannerContainer = styled.div`
  position: relative;
  margin-bottom: -34px;

  @media (min-width: 768px) {
    margin-bottom: -79px;
  }
`;

export const RenewalPetNameInput = styled.input`
  position: relative;
  font-family: ${fontFamilyCamptonRegular};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.purple7};
  background-color: ${colors.gray2};
  appearance: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  border-radius: 5px;
  width: 240px;
  height: 50px;
  box-sizing: border-box;
  padding: 0px 8px;
  cursor: text;
  color: ${colors.black};
  margin-top: 10px;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const AutumnCompetitionBackgroundContainer = styled.div`
  background-color: ${colors.gray3};
  position: relative;
  overflow: hidden;
`;

export const AutumnCompetitionTermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 24px;

  @media (min-width: 768px) {
    padding: 50px 40px;
    width: 650px;
  }

  @media (min-width: 1024px) {
    width: 800px;
    margin: 0 auto;
    padding: 130px 0;
  }
`;

export const AutumnCompetitionTermsHeader = styled.h3`
  font-size: 25px;
  padding-bottom: 0;
  font-weight: 500;
  font-family: ${fontFamilyCamptonRegular};
  margin-bottom: 30px;
  letter-spacing: 0;

  @media (min-width: 768px) {
    font-size: 35px;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 50px;
  }
`;

export const AutumnCompetitionTermsListContainer = styled.ul`
  padding-left: 25px;
`;

export const AutumnCompetitionTermsOrderedListContainer = styled.ol`
  padding-left: 25px;
`;

export const AutumnCompetitionTermsListItem = styled.li<{
  $isBold?: boolean;
  $isSubHeader?: boolean;
  $isPurple?: boolean;
  $hasPadding?: boolean;
}>`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: ${({ $isBold }) =>
    $isBold ? fontFamilyCamptonSemiBold : fontFamilyCamptonBook};
  list-style-position: outside;
  line-height: normal;
  letter-spacing: 0;
  list-style-type: ${({ $isSubHeader }) => $isSubHeader && 'none'};
  color: ${({ $isPurple }) => ($isPurple ? colors.purple7 : colors.black)};
  padding-left: ${({ $hasPadding }) => $hasPadding && '25px'};
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const AutumnCompetitionTermsInternalLink = styled(Link)`
  margin-bottom: 0;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0;
  font-weight: 600;
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const AutumnCompetitionTermsExternalLink = styled.a`
  margin-bottom: 0;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0;
  color: ${colors.pink2};
  font-family: ${fontFamilyCamptonSemiBold};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const AutumnCompetitionTermsListText = styled.p<{
  $hasPadding?: boolean;
}>`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: ${fontFamilyCamptonBook};
  line-height: normal;
  letter-spacing: 0;
  padding-left: ${({ $hasPadding }) => $hasPadding && '25px'};

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const RefreshedPrimaryButton = styled.a`
  display: flex;
  height: 68px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${colors.pink2};
  color: ${colors.white};
  font-size: 18px;
  font-family: ${fontFamilyCamptonSemiBold};
  border-radius: 8px;
  margin-bottom: 24px;
  transition-property: all;
  transition-duration: 150ms;
  border: 2px solid ${colors.pink2};

  &:hover {
    background-color: ${colors.white};
    color: ${colors.pink2};
  }

  @media (min-width: 768px) {
    width: 272px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 32px;
  }
`;
